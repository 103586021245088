@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Roboto&display=swap);






.styled-select {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
}

.styled-select > div:first-child, .styled-select > div:nth-child(2) {
    height: 56px;
}

.styled-select input {
    width: 100% !important;
}

dl.horizontal dl {
    float: left;
}

dl.horizontal dd {
    float: right;
}
.styled-select {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
}

.styled-select > div:first-child, .styled-select > div:nth-child(2) {
    height: 56px;
}

.styled-select input {
    width: 100% !important;
}

ol:not([type=a]) { counter-reset: item }
ol:not([type=a]) > li{ display: block; margin-bottom: 10px }
ol:not([type=a]) > li:before { content: counters(item, ".") " "; counter-increment: item }
.styled-select {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
}

.styled-select > div:first-child, .styled-select > div:nth-child(2) {
    height: 56px;
}

.styled-select input {
    width: 100% !important;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

